import style from "./toast.scss";
import {useEffect} from "preact-compat";

const Toast = ({text, open, setOpen, isError = true}) => {

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                setOpen(false);
            }, 3000);
        }
    }, [open]);

    return (
        <>
            {open && (<div className={`${style.toastContainer} ${!isError && style.success}`}>{text}</div>)}
        </>
    )
};

export default Toast;
