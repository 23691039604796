import {axios} from "../shared/axios";

export const makePaymentMethodDefault = async (id) => {
    try {
        await axios.put('payment-methods/make-default', {paymentMethodId: id});
        return {success: true};
    } catch (e) {
        return {
            success: false
        };
    }
};
