import Header from "../../components/generic/ui-components/header/header";
import style from "./auth.scss";
import QuizCard from "../../components/quiz-card/quiz-card";
import {useEffect, useState} from "preact-compat";
import {route} from "preact-router";
import {getQuizzes} from "../../api/quiz/get-quizzes";

const SelectQuiz = ({matches}) => {
    const {id} = matches;
    const [quizzes, setQuizzes] = useState([]);

    useEffect(async () => {
        if (typeof window !== "undefined") {
            let user = localStorage.getItem('user');
            if (!user) {
                route('/login');
                return;
            }
        }
        if (!id) {
            route('/courses');
            return;
        }
        const result = await getQuizzes(id);
        if (result.quizzes.length === 0) {
            route('/buy-course');
            return;
        }
        setQuizzes(result.quizzes);
    }, []);

    return (<>
        <Header></Header>
        <div className={style.space}>
            <div className={style.listContainer}>
                {quizzes.map((o, i) => {
                    return (<QuizCard key={i} quiz={o}/>)
                })}
            </div>
        </div>
    </>)
};

export default SelectQuiz;
