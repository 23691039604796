import {axiosPayment} from "../shared/axios";

export class PaymentProcessor {

    data;
    token = null;

    resolve;
    reject;

    constructor(data) {
        this.data = data;
    }

    pay(e) {
        e.preventDefault();
        return new Promise(async (resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
            this.token = await this.retrieveToken();
            if (this.token) {
                await this.sendToConverge();
            }
        });
    }

    async retrieveToken() {
        try {
            const res = await axiosPayment.get("payment-gateway/token?amount=" + this.getAmount() + '&isEcheck=false');
            return res.data.token;
        } catch (err) {
            if (err?.response?.data?.error) {
                this.reject({message: err.response.data.error});
            } else {
                this.reject(err);
            }
        }
        return null;
    }

    getAmount() {
        return this.data.amount === 0 ? "0.01" : Number(this.data.amount).toFixed(2);
    }

    serializeDataForConverge() {
        return {
            ssl_amount: this.getAmount(),
            ssl_transaction_type: this.data.amount === 0 ? "ccauthonly" : "ccsale",
            ssl_txn_auth_token: this.token,
            ssl_exp_date: this.serializeExpirationDate().replace(/\s/g, ''),
            ssl_cvv2cvc2: this.data.cvv,
            ssl_card_number: this.data.cardNumber.replace(/\s/g, ''),
            ssl_email: this.data.email,
            ssl_get_token: "Y",
            ssl_add_token: "Y"
        };
    }

    serializeExpirationDate() {
        if (this.data.expirationDate.length === 4) {
            return this.data.expirationDate;
        }
        let dates = this.data.expirationDate.split('/');
        if (dates[1].length === 2) {
            return dates[0] + dates[1];
        }
        return dates[0] + dates[1].slice(-2);
    }

    async sendTransaction(data) {
        return axiosPayment.post("transaction", data)
            .then(() => {
                this.resolve();
            })
            .catch((err) => {
                if (err?.response?.data?.error) {
                    this.reject({message: err.response.data.error});
                } else {
                    this.reject(err);
                }
            });
    }

    async addPaymentMethod(data) {
        return axiosPayment.post("payment-methods", data)
            .then(() => {
                this.resolve();
            })
            .catch((err) => {
                if (err?.response?.data?.error) {
                    this.reject({message: err.response.data.error});
                } else {
                    this.reject(err);
                }
            });
    }

    async sendToConverge() {
        const callback = {
            onError: (error) => {
                console.error(error);
                this.reject({message: error});
            },
            onDeclined: (error) => {
                console.error(error);
                this.reject({message: error.errorName});
            },
            onApproval: (response) => {
                try {
                    console.log(response.ssl_amount);
                    if (response.ssl_amount === "0.10") {
                        this.addPaymentMethod({
                            ssl_card_number: response.ssl_card_number,
                            ssl_card_short_description: response.ssl_card_short_description,
                            email: response.ssl_email,
                            ssl_exp_date: response.ssl_exp_date,
                            ssl_token: response.ssl_token,
                        });
                    } else {
                        this.sendTransaction({
                            amount: response.ssl_amount,
                            ssl_approval_code: response.ssl_approval_code,
                            ssl_card_number: response.ssl_card_number,
                            ssl_card_short_description: response.ssl_card_short_description,
                            ssl_card_type: response.ssl_card_type,
                            email: response.ssl_email,
                            ssl_exp_date: response.ssl_exp_date,
                            ssl_get_token: response.ssl_get_token,
                            ssl_txn_id: response.ssl_txn_id,
                            ssl_transaction_type: response.ssl_transaction_type,
                            ssl_token_response: response.ssl_token_response,
                            ssl_token_provider: response.ssl_token_provider,
                            ssl_token_format: response.ssl_token_format,
                            ssl_token: response.ssl_token,
                            ssl_result_message: response.ssl_result_message,
                            ssl_result: response.ssl_result,
                            ssl_issuer_response: response.ssl_issuer_response,
                            ssl_merchant_initiated_unscheduled: response.ssl_merchant_initiated_unscheduled,
                            ssl_oar_data: response.ssl_oar_data,
                            ssl_partner_app_id: response.ssl_partner_app_id,
                            ssl_ps2000_data: response.ssl_ps2000_data,
                            ssl_txn_time: response.ssl_txn_time,
                            courses: this.data.courses
                        });
                    }

                } catch (err) {
                    this.reject({message: err.error});
                }
            },
        };
        await window.ConvergeEmbeddedPayment.pay(this.serializeDataForConverge(), callback);
    }

}
