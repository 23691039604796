import Header from "../../components/generic/ui-components/header/header";
import style from "./auth.scss";
import {useEffect, useState} from "preact-compat";
import {route} from "preact-router";
import {getQuiz} from "../../api/quiz/get-quiz";
import LayoutContainer from "../../components/generic/ui-components/layout-container/layout-container";
import Text from "../../components/generic/ui-components/text/text";
import FooterLayoutContainer
    from "../../components/generic/ui-components/footer-layout-container/footer-layout-container";
import PrimaryButton from "../../components/generic/ui-components/primary-button/primary-button";
import {answer} from "../../api/quiz/answer";
import {lazyLoad} from "../../utils/lazy-load";
import Toast from "../../components/generic/ui-components/toast/toast";

const TakeQuiz = ({matches}) => {

    const {id} = matches;

    const [quiz, setQuiz] = useState(null);
    const [trackId, setTrackId] = useState(null);
    const [question, setQuestion] = useState(null);
    const [index, setIndex] = useState(1);
    const [explanation, setExplanation] = useState(null);
    const [optionSelected, setOptionSelected] = useState(null);
    const [answers, setAnswers] = useState([]);

    const [showError, setShowError] = useState(false);
    const [text, setText] = useState('');


    useEffect(async () => {
        if (typeof window !== "undefined") {
            let user = localStorage.getItem('user');
            if (!user) {
                route('/login');
                return;
            }
        }
        const result = await getQuiz(id);
        if (result.success) {
            setTrackId(result.data.trackId);
            setQuiz(result.data.quiz);
            setQuestion(result.data.quiz.questions[result.data.answers.length]);
            setIndex(result.data.answers.length + 1);
            setAnswers(result.data.answers);
        } else {
            setShowError(true);
            setText(result.message);
            setTimeout(() => {
                route('/buy-course');
            }, 1000);
        }

    }, []);

    useEffect(async () => {
        await lazyLoad(['https://cdn.jsdelivr.net/npm/seamless-scroll-polyfill@latest/lib/bundle.min.js']);
        seamless.polyfill();
    });

    const showExplanation = async () => {

        if (optionSelected === null) {
            return;
        }
        setExplanation(question.explanation);
        if (typeof window !== "undefined") {
            setTimeout(() => {
                window.scrollTo({
                    top: document.getElementById('explanation' + question._id).offsetTop - 60,
                    behavior: 'smooth'
                });
            }, 50);
        }
        setAnswers([...answers, {optionSelected}]);
        await answer(trackId, optionSelected);
    }

    const next = () => {
        if (index < quiz.totalQuestions) {
            setOptionSelected(null);
            setQuestion(quiz.questions[index]);
            setIndex(index + 1);
            setExplanation(null);
        } else {
            route('/quiz-result?id=' + trackId);
        }
        if (typeof window !== "undefined") {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    const back = () => {
        if (index > 1) {
            setOptionSelected(answers[index - 2].optionSelected);
            setQuestion(quiz.questions[index - 2]);
            setIndex(index - 1);
            setExplanation(quiz.questions[index - 2].explanation);
        } else {
            history.back();
        }
        if (typeof window !== "undefined") {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    const isCorrect = quiz && optionSelected === question.correctOption;
    return (<>
        {quiz && <>
            <Header haveBack={true} back={back} title={quiz.name}></Header>
            <LayoutContainer haveHeader={true} haveFooter={true}>
                <div className={style.mB20}>
                    <Text type={'h3'}>Q. {index}/{quiz.totalQuestions}</Text>
                </div>
                <div className={style.mB20}>
                    <Text type={'description'}><span dangerouslySetInnerHTML={{__html: question.content}}></span></Text>
                </div>
                <div className={`${style.listContainer} ${explanation && style.disable}`}>
                    {question.options.map((option, index) => {
                        return <div
                            className={`${style.radioContainer} ${optionSelected === index && style.selected}`}
                            key={index} onClick={() => setOptionSelected(index)}>
                            <Text type={'description'} primary={optionSelected === index}
                                  white={optionSelected !== index}
                                  bold={true}>{String.fromCharCode(index + 65)}.</Text>

                            <Text type={'description'} primary={optionSelected === index}
                                  white={optionSelected !== index}>{option}</Text>
                            {(!explanation || optionSelected !== index) && (<span className={style.radio}></span>)}
                            {(explanation && isCorrect && optionSelected === index) && (
                                <span
                                    className={style.correctIncorrect}
                                ><img alt="correct" src='../../assets/global/check-mark-correct.svg'/></span>)}
                            {(explanation && !isCorrect && optionSelected === index) && (
                                <span
                                    className={style.correctIncorrect}
                                ><img alt="incorrect" src='../../assets/global/x-incorrect.svg'/></span>)}
                        </div>
                    })}
                </div>
                {explanation && (
                    <div id={'explanation' + question._id} className={`${style.listContainer} ${style.mT20}`}>
                        <Text type={'h3'}>Explanation</Text>
                        <Text type={'description'}>
                            <span dangerouslySetInnerHTML={{__html: explanation}}></span>
                        </Text>
                    </div>)}

                {!explanation && (<FooterLayoutContainer>
                    <PrimaryButton onClick={showExplanation}>Submit</PrimaryButton>
                </FooterLayoutContainer>)}
                {explanation && (<FooterLayoutContainer>
                    <PrimaryButton onClick={next}>{index === quiz.totalQuestions ? 'Finish' : 'Next'}</PrimaryButton>
                </FooterLayoutContainer>)}
            </LayoutContainer></>}
        <Toast text={text} open={showError} setOpen={setShowError}/>
    </>)
};

export default TakeQuiz;
