import {axios} from "../shared/axios";

export const getMyQuizzes = async () => {
    let result = await axios.get('quizzes/my-quizzes');
    try {
        return {success: true, data: result.data};
    } catch (e) {
        return {
            success: false, message: result.data.error
        };
    }
};
