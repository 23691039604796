import Text from "../../components/generic/ui-components/text/text";
import PrimaryButton from "../../components/generic/ui-components/primary-button/primary-button";
import style from "./auth.scss";
import LayoutContainer from "../../components/generic/ui-components/layout-container/layout-container";
import FooterLayoutContainer
    from "../../components/generic/ui-components/footer-layout-container/footer-layout-container";
import Header from "../../components/generic/ui-components/header/header";
import CreditCardInput from 'react-credit-card-input';
import {route} from "preact-router";
import {useEffect, useState} from "preact-compat";
import {PaymentProcessor} from "../../api/payment/payment";
import Toast from "../../components/generic/ui-components/toast/toast";

const Payment = () => {

    const [courses, setCourses] = useState([]);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [cardNumber, setCardNumber] = useState('');
    const [expiry, setExpiry] = useState('');
    const [cvc, setCvc] = useState('');
    const [showError, setShowError] = useState(false);
    const [text, setText] = useState('');

    useEffect(() => {
        if (typeof window !== "undefined") {

            let script = document.createElement('script');
            if (window.location.href.includes('localhost') || window.location.href.includes('demo')) {
                script.src = "https://demo.convergepay.com/hosted-payments/Checkout.js";
            } else {
                script.src = "https://api.convergepay.com/hosted-payments/Checkout.js";
            }
            document.body.appendChild(script);
        }
    }, []);

    useEffect(async () => {
        if (typeof window !== "undefined") {
            let user = localStorage.getItem('user');
            if (!user) {
                route('/login');
                return;
            }
            let courses = localStorage.getItem('courses');
            if (!courses || JSON.parse(courses).length === 0) {
                route('/buy-course');
                return;
            }
            setCourses(JSON.parse(courses));
            setTotal(JSON.parse(courses).reduce((a, b) => a + b.price, 0));
        }
    }, []);

    const submit = async (e) => {
        setIsLoading(true);
        let payment = new PaymentProcessor({
            amount: total,
            cardNumber: cardNumber,
            expirationDate: expiry,
            cvv: cvc,
            email: JSON.parse(localStorage.getItem('user')).email,
            courses: courses.filter(o => o._id)
        });
        try {
            await payment.pay(e);
            setIsLoading(false);
            localStorage.removeItem('courses');
            route('/courses');
        } catch (e) {
            setText(e.message);
            setShowError(true);
            setIsLoading(false);
        }
    }

    return (<>
        <Header haveBack={true}></Header>
        <LayoutContainer innerClass={style.contentContainer} haveHeader={true}>
            <div className={style.textCenter}>
                <Text type={'h1'}>Payment</Text>
            </div>
            <div className={style.cardContainer}>
                <CreditCardInput
                    cardNumberInputProps={{value: cardNumber, onChange: (e) => setCardNumber(e.target.value)}}
                    cardExpiryInputProps={{value: expiry, onChange: (e) => setExpiry(e.target.value)}}
                    cardCVCInputProps={{value: cvc, onChange: (e) => setCvc(e.target.value)}}
                    fieldClassName="input"
                />
            </div>
            <div className={style.listContainer}>
                {courses.map((o, i) => {
                    return (<div key={i} className={style.item}>
                        <Text type={'text'} black={true}>{o.name}</Text>
                        <Text type={'text'} black={true}>${o.price} / month</Text>
                    </div>)
                })}
                <div className={style.item}>
                    <Text type={'text'} black={true} bold={true}>Total:</Text>
                    <Text type={'text'} black={true} bold={true}>${total} / month</Text>
                </div>
            </div>
            <FooterLayoutContainer>
                <PrimaryButton onClick={submit} isLoading={isLoading}>Pay ${total}</PrimaryButton>
            </FooterLayoutContainer>
        </LayoutContainer>
        <Toast text={text} open={showError} setOpen={setShowError}/>
    </>)
};

export default Payment;
