import {axios} from "../shared/axios";

export const getSubscriptions = async () => {
    let result = await axios.get('subscriptions');
    try {
        return {success: true, subscriptions: result.data};
    } catch (e) {
        return {
            success: false, message: result.data.error
        };
    }
};
