import {axios} from "../shared/axios";

export const getQuiz = async (id) => {
    try {
        let result = await axios.post('quizzes/take', {quizId: id});
        return {success: true, data: result.data};
    } catch (e) {
        return {
            success: false, message: e.response.data.error
        };
    }
};
