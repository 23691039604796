import style from "./quiz-card.scss";
import Text from "../generic/ui-components/text/text";
import {route} from "preact-router";

const QuizCard = ({quiz}) => (
    <div className={`${style.quizContainer}`} onClick={() => {
        route(`/quiz?id=${quiz._id}`)
    }}>
        <div className={`${style.quizImage}`} style={{backgroundImage: 'url(' + quiz.image + ')'}}></div>
        <div className={style.quizContent}>
            <Text type={'h4'}>{quiz.name}</Text>
            <Text type={'small-description'}>{quiz.totalQuestions} questions</Text>
        </div>
    </div>
);

export default QuizCard;
