import Text from "../../components/generic/ui-components/text/text";
import PrimaryButton from "../../components/generic/ui-components/primary-button/primary-button";
import style from "./auth.scss";
import LayoutContainer from "../../components/generic/ui-components/layout-container/layout-container";
import Input from "../../components/generic/ui-components/input/input";
import FooterLayoutContainer
    from "../../components/generic/ui-components/footer-layout-container/footer-layout-container";
import {route} from "preact-router";
import Header from "../../components/generic/ui-components/header/header";
import {useEffect, useState} from "preact-compat";
import Toast from "../../components/generic/ui-components/toast/toast";
import {login} from "../../api/user/login";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [text, setText] = useState('Email and Password are not valid.');

    useEffect(async () => {
        if (typeof window !== "undefined") {
            let user = localStorage.getItem('user');
            if (user) {
                route('/courses');
                return;
            }
        }
    }, []);

    const submit = async () => {
        if (!email || email.length === 0 || !password || password.length === 0) {
            setShowError(true);
            return;
        }
        setIsLoading(true);
        const result = await login({email, password});
        if (!result.success) {
            setText(result.message);
            setShowError(true);
        }
        setIsLoading(false);
    }
    return (<>
        <Header></Header>
        <LayoutContainer innerClass={style.contentContainer} haveHeader={true}>
            <div className={style.textCenter}>
                <Text type={'h1'}>Login</Text>
            </div>
            <Input placeholder={'Email'} type={'email'} onChange={(v) => setEmail(v)}/>
            <Input placeholder={'Password'} type={'password'} onChange={(v) => setPassword(v)}/>

            <div>
                <PrimaryButton onClick={submit} isLoading={isLoading}>Login</PrimaryButton>
            </div>
            <FooterLayoutContainer>
                <Text type={'text'} link={true} onClick={() => route('/forget-password')}>Forgot password?</Text>
            </FooterLayoutContainer>
        </LayoutContainer>
        <Toast text={text} open={showError} setOpen={setShowError}/>
    </>)
};

export default Login;
