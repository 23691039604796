import Axios from 'axios';

function authRequestInterceptor(config) {
    if (typeof window !== "undefined") {
        const user = localStorage.getItem('user');
        if (user) {
            config.headers.authorizationtoken = `${JSON.parse(user).token}`;
        }
        config.headers.Accept = 'application/json';
    }
    if (!config.params) {
        config.params = {};
    }
    config.params['hostname'] = 'practice4success.net';
    return config;
}

export const axios = Axios.create({
    // baseURL: 'http://localhost:5001/api/',
    baseURL: 'https://payments.maxcheckout.com/api/'
});

export const axiosPayment = Axios.create({
    // baseURL: 'https://payment.practice4success.net/api/',
    baseURL: 'https://payments.maxcheckout.com/api/'
    // baseURL: typeof window !== "undefined" && window.location.href.includes('demo') ? 'https://paymentdemo.practice4success.net/api/' : 'https://payment.practice4success.net/api/'
});
axiosPayment.interceptors.request.use(authRequestInterceptor);
axios.interceptors.request.use(authRequestInterceptor);
