import Text from "../../components/generic/ui-components/text/text";
import PrimaryButton from "../../components/generic/ui-components/primary-button/primary-button";
import style from "./auth.scss";
import LayoutContainer from "../../components/generic/ui-components/layout-container/layout-container";
import Input from "../../components/generic/ui-components/input/input";
import FooterLayoutContainer
    from "../../components/generic/ui-components/footer-layout-container/footer-layout-container";
import {route} from "preact-router";
import Header from "../../components/generic/ui-components/header/header";
import {useEffect, useState} from "preact-compat";
import Toast from "../../components/generic/ui-components/toast/toast";
import {forgetPassword} from "../../api/user/forget-password";

const ForgetPassword = () => {

    const [email, setEmail] = useState('');

    const [showError, setShowError] = useState(false);
    const [text, setText] = useState('Email is not valid.');

    const [showNotification, setShowNotification] = useState(false);
    const [notification, setNotification] = useState(null);

    useEffect(async () => {
        if (typeof window !== "undefined") {
            let user = localStorage.getItem('user');
            if (user) {
                route('/courses');
                return;
            }
        }
    }, []);

    const submit = async () => {
        if (!email || email.length === 0) {
            setShowError(true);
            return;
        }
        const result = await forgetPassword({email});
        if (!result.success) {
            setText(result.message);
            setShowError(true);
        } else {
            setNotification('Email sent.');
            setShowNotification(true);
            setEmail('');
        }
    }

    return (<>
        <Header></Header>
        <LayoutContainer innerClass={style.contentContainer} haveHeader={true}>
            <div className={style.textCenter}>
                <Text type={'h1'}>Forgot password</Text>
            </div>
            <Input placeholder={'Email'} type={'email'} value={email} onChange={(v) => setEmail(v)}/>
            <div>
                <PrimaryButton onClick={submit}>Submit</PrimaryButton>
            </div>
            <FooterLayoutContainer>
                <Text type={'text'} link={true} onClick={() => route('/login')}>Login</Text>
            </FooterLayoutContainer>
        </LayoutContainer>
        <Toast text={text} open={showError} setOpen={setShowError}/>
        <Toast text={notification} open={showNotification} setOpen={setShowNotification} isError={false}/>
    </>)
};

export default ForgetPassword;
