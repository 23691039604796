import {axios} from "../shared/axios";

export const forgetPassword = async ({email}) => {
    let result = await axios.post('customer-users/forget-password', {email});
    try {
        return {success: true};
    } catch (e) {
        return {
            success: false, message: result.data.error
        };
    }
};
