import style from "./ripple.scss";
import {useState} from "preact-compat";

const Ripple = ({children, onClick, className, rippleColor = '#ffffff'}) => {

    const [rippleStyle, setRippleStyle] = useState({});
    const [isRippleVisible, setIsRippleVisible] = useState(false);

    const callCleanUp = () => {
        setTimeout(() => {
            setRippleStyle({});
            setIsRippleVisible(false);
        }, 1000);
    }

    const showRipple = (e) => {
        const rippleContainer = e.currentTarget;
        const size = rippleContainer.offsetWidth;
        const pos = rippleContainer.getBoundingClientRect();
        const x = e.pageX - pos.x - (size / 2);
        const y = e.pageY - pos.y - (size / 2);

        const spanStyles = {
            top: y + 'px',
            left: x + 'px',
            height: size + 'px',
            width: size + 'px',
            background: rippleColor
        };
        setRippleStyle(spanStyles);
        setIsRippleVisible(true);
    }

    const renderRipple = () => {
        if (rippleStyle && isRippleVisible) {
            return <span style={rippleStyle} className={style.rippleSpan}></span>
        }
    }

    return (
        <div className={`${style.ripple} ${className}`} onClick={onClick}>
            {children}
            <div className={style.rippleContainer} onMouseDown={showRipple}
                 onMouseUp={callCleanUp}>
                {renderRipple()}
            </div>
        </div>
    )
};

export default Ripple;
