import {Provider} from '@preact/prerender-data-provider';
import {Router} from "preact-router";
import './styles/main.scss';
import Landing from "./routes/landing/landing";
import Signup from "./routes/auth/signup";
import Login from "./routes/auth/login";
import ForgetPassword from "./routes/auth/forget-password";
import ResetPassword from "./routes/auth/reset-password";
import Payment from "./routes/auth/payment";
import SelectQuiz from "./routes/auth/select-quiz";
import TakeQuiz from "./routes/auth/take-quiz";
import QuizResult from "./routes/auth/quiz-result";
import BuyCourse from "./routes/auth/buy-course";
import SelectCourses from "./routes/auth/select-course";
import MyAccount from "./routes/auth/my-account";
import AddPaymentMethod from "./routes/auth/add-payment-method";
import MyQuizzes from "./routes/auth/my-quizzes";

const App = (props) => (
    <Provider value={props}>
        <Router>
            <Landing path="/"/>
            <Signup path="/signup"/>
            <Login path="/login"/>
            <ForgetPassword path="/forget-password"/>
            <ResetPassword path="/reset-password"/>
            <Payment path="/payment"/>
            <BuyCourse path="/buy-course"/>
            <SelectCourses path="/courses"/>
            <SelectQuiz path="/quizzes"/>
            <TakeQuiz path="/quiz"/>
            <QuizResult path="/quiz-result"/>
            <MyAccount path="/my-account"></MyAccount>
            <MyQuizzes path="/my-quizzes"></MyQuizzes>
            <AddPaymentMethod path="/add-payment-method"></AddPaymentMethod>
        </Router>
    </Provider>
)

export default App;
