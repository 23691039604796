import style from "./checkbox.scss";


const Checkbox = ({onChange, label, value}) => (
    <label className={style.checkboxContainer}>{label}
        <input type="checkbox" onChange={(e) => onChange(e.target.checked ? value : null)}/>
        <span className={style.checkmark}></span>
    </label>
);

export default Checkbox;
