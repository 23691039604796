import Header from "../../components/generic/ui-components/header/header";
import style from "./auth.scss";
import {useEffect, useState} from "preact-compat";
import {route} from "preact-router";
import {getMyCourses} from "../../api/course/get-my-courses";
import CourseCard from "../../components/course-card/course-card";
import BuyCourse from "./buy-course";
import {getCourses} from "../../api/course/get-courses";

const SelectCourses = () => {

    const [courses, setCourses] = useState([]);
    const [buyCourses, setBuyCourses] = useState([]);

    useEffect(async () => {
        if (typeof window !== "undefined") {
            let user = localStorage.getItem('user');
            if (!user) {
                route('/login');
                return;
            }
        }
        const result = await getMyCourses();
        if (result.courses.length === 0) {
            route('/buy-course');
            return;
        }
        setCourses(result.courses);
        const resultBuyCourses = await getCourses();
        setBuyCourses(resultBuyCourses.courses);
    }, []);

    return (<>
        <Header></Header>
        <div className={style.space}>
            <div className={style.listContainer}>
                <h2
                    style={{
                        margin: "auto",
                        color: "white",
                    }}
                >My Courses</h2>
                {courses.map((o, i) => {
                    return (<CourseCard key={i} course={o}/>)
                })}
                <div
                    style={{
                        margin: "0.5em auto 0 auto",
                        border: "1px solid grey",
                        color: "white",
                        width: "100%",
                        maxWidth: "400px",
                    }}
                />
                {buyCourses.map((o, i) => {
                    return (<CourseCard key={i} course={o} advertisement={true}/>)
                })}
            </div>
        </div>
    </>)
};

export default SelectCourses;
