const loadedLibs = [];
const loadFile = (file) => {
    return new Promise((resolve, reject) => {
        if (loadedLibs.indexOf(file) > -1) {
            resolve(file);
            return;
        }
        let script;

        if (file.indexOf('.css') > -1) {
            script = document.createElement('link');
            script.href = file;
            script.rel = 'stylesheet';
        } else {
            script = document.createElement('script');
            script.src = file;
        }

        script.addEventListener('load', () => {
            loadedLibs.push(file);
            resolve(script);
        }, false);

        script.addEventListener('error', (err) => {
            reject(script);
            console.error(err);
        }, false);

        document.body.appendChild(script);
    });
}
export const lazyLoad = (files) => {
    let promises = [];
    for (let file of files) {
        promises.push(loadFile(file));
    }
    return Promise.all(promises);
}
