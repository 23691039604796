import {axios} from "../shared/axios";

export const getQuizzes = async (courseId) => {
    let result = await axios.get('quizzes/list?courseId=' + courseId);
    try {
        return {success: true, quizzes: result.data};
    } catch (e) {
        return {
            success: false, message: result.data.error
        };
    }
};
