import style from "./primary-button.scss";
import Ripple from "../../../generic/ui-components/ripple/ripple";

const PrimaryButton = ({children, onClick, inline, isLoading = false}) => (
    <Ripple onClick={onClick}
            className={`${style.primaryButton} ${inline && style.inline} ${isLoading && style.disabled}`}>
        <button className={`${style.button}`}>
            {isLoading && (<div className={`${style.btnIcon}`}>
                <img src={'/assets/loading.svg'} alt={'icon-btn'}/>
            </div>)}
            {children}
        </button>
    </Ripple>
);

export default PrimaryButton;
