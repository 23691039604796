import Text from "../../components/generic/ui-components/text/text";
import PrimaryButton from "../../components/generic/ui-components/primary-button/primary-button";
import style from "./auth.scss";
import LayoutContainer from "../../components/generic/ui-components/layout-container/layout-container";
import FooterLayoutContainer
    from "../../components/generic/ui-components/footer-layout-container/footer-layout-container";
import Header from "../../components/generic/ui-components/header/header";
import Checkbox from "../../components/generic/ui-components/checkbox/checkbox";
import {route} from "preact-router";
import {useEffect, useState} from "preact-compat";
import {getCourses} from "../../api/course/get-courses";
import Toast from "../../components/generic/ui-components/toast/toast";

const BuyCourse = () => {

    const [courses, setCourses] = useState([]);

    const [selectedCourses, setSelectedCourses] = useState([]);

    const [showError, setShowError] = useState(false);
    const [text, setText] = useState('Please select at least 1 course.');

    useEffect(async () => {
        if (typeof window !== "undefined") {
            let user = localStorage.getItem('user');
            if (!user) {
                route('/login');
                return;
            }
            const result = await getCourses();
            setCourses(result.courses);
        }
    }, []);

    const toggleCourse = (courseId) => {
        let courseIndex = selectedCourses.indexOf(courseId);
        if (courseIndex > -1) {
            setSelectedCourses(selectedCourses.slice(courseIndex, 1));
        } else {
            setSelectedCourses([...selectedCourses, courseId]);
        }
    }

    const submit = () => {
        if (selectedCourses.length === 0) {
            setShowError(true);
            return;
        }
        if (typeof window !== "undefined") {
            localStorage.setItem('courses', JSON.stringify(courses.filter(o => selectedCourses.indexOf(o._id) > -1)));
        }
        route('/payment');
    }

    return (<>
        <Header></Header>
        <LayoutContainer innerClass={style.contentContainer} haveHeader={true}>
            <div className={style.textCenter}>
                <Text type={'h1'}>Select Courses</Text>
            </div>
            <div className={style.listContainer}>
                {courses.map((o, i) => {
                    return <Checkbox key={i} label={`${o.name} ($${o.price}/month)`} value={o._id}
                                     onChange={toggleCourse}></Checkbox>
                })}
            </div>
            <FooterLayoutContainer>
                <PrimaryButton onClick={submit}>Purchase</PrimaryButton>
            </FooterLayoutContainer>
        </LayoutContainer>
        <Toast text={text} open={showError} setOpen={setShowError}/>
    </>)
};

export default BuyCourse;
