import style from "./quiz-track-card.scss";
import Text from "../generic/ui-components/text/text";
import {route} from "preact-router";
import PrimaryButton from "../generic/ui-components/primary-button/primary-button";

const QuizTrackCard = ({track}) => {
    const getFormattedDate = () => {
        const date = new Date();

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-2);

        return `${month}/${day}/${year}`;
    }

    return <div className={`${style.quizContainer}`} onClick={() => {
        route(`/quiz?id=${track.quiz._id}`)
    }}>
        <div className={style.quizContent}>
            <Text type={'h4'}>{track.quiz.name}</Text>
            <Text type={'description'}>Score {(track.score * (100 / track.quiz.totalQuestions)).toFixed(1)}%</Text>
            <Text
                type={'small-description'}>{track.status === 'COMPLETED' ? 'Completed on ' + getFormattedDate() : track.numberOfAnswers + '/' + track.quiz.totalQuestions + ' questions'} </Text>
            <div>
                <PrimaryButton onClick={() => {
                    route(`/quiz?id=${track.quiz._id}`)
                }}>{track.status === 'COMPLETED' ? 'Retake' : 'Resume'}</PrimaryButton>
            </div>
        </div>
    </div>
}

export default QuizTrackCard;
