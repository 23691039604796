import style from "./secondary-button.ines.scss";
import Ripple from "../../../generic/ui-components/ripple/ripple";

const SecondaryButtonInes = ({children, innerClass = '', onClick}) => (
    <Ripple onClick={onClick} className={`${style.secondaryButton}`} rippleColor={'var(--primary-color)'}>
        <button className={`${style.button} ${innerClass}`}>{children}</button>
    </Ripple>
);

export default SecondaryButtonInes;
