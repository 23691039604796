import style from "./text.scss";

const Text = ({
                  type,
                  children,
                  link = false,
                  innerClass = '',
                  uppercase = false,
                  bold = false,
                  fontSize = null,
                  isCenter = false,
                  onClick = null,
                  primary = false,
                  white = false,
                  black = false
              }) => {
    let innerStyle = {};
    if (fontSize) {
        innerStyle.fontSize = fontSize + 'px';
    }
    return (<>
            {type === 'h1' && (<h1 className={`${isCenter && style.textCenter} ${white && style.white}`}>{children}
            </h1>)}
            {type === 'h2' && (<h2>{children}</h2>)}
            {type === 'h3' && (
                <h3 className={`${innerClass}  ${white && style.white} ${bold && style.bold}`}> {children}</h3>)}
            {type === 'h4' && (<h4>{children}</h4>)}
            {type === 'sub-title' && (
                <p className={`${style.subTitle} ${isCenter && style.textCenter}`}>{children}</p>)}
            {type === 'description' && (
                <p className={`${style.description} ${bold && style.bold} ${primary && style.primary} ${white && style.white}`}>{children}</p>)}
            {type === 'small-description' && (
                <p className={`${style.smallDescription} ${link && style.link} ${bold && style.bold}`}
                   style={innerStyle}>{children}</p>)}
            {type === 'text' && (
                <p onClick={onClick}
                   className={`${style.text} ${link && style.link} ${bold && style.bold} ${uppercase && style.uppercase} ${black && style.black}`}>{children}</p>)}
        </>
    );
}

export default Text;
