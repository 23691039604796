import {axios} from "../shared/axios";

export const cancelSubscription = async (id) => {
    try {
        await axios.put('subscriptions/cancel/' + id);
        return {success: true};
    } catch (e) {
        return {
            success: false
        };
    }
};
