import style from "./subscription-card.scss";
import Text from "../generic/ui-components/text/text";
import {removePaymentMethod} from "../../api/payment/remove-payment-method";
import {cancelSubscription} from "../../api/payment/cancel-subscription";

const SubscriptionCard = ({subscription, enableLoading, refresh}) => {
    const cancel = async () => {
        enableLoading();
        await cancelSubscription(subscription._id);
        refresh();
    };

    return <div className={style.subscriptionContainer}>
        <div className={style.subscriptionContent}>
            <div className={style.subscriptionDetails}>
                <Text type={'h4'}>{subscription.reason} (${subscription.price}/mo)</Text>
                <Text type={'small-description'}>
                    Next bill on {subscription.nextBillingDate}
                </Text>
            </div>
        </div>
        <div className={style.subscriptionActionContainer}>
            <div>
                {subscription.isActive && <Text type={'text'} black={true}>Active</Text>}
                {!subscription.isActive && <Text type={'text'} black={true}>Cancelled</Text>}
            </div>
            <div>
                {subscription.isActive &&
                    <Text type={'text'} black={true} link={true} onClick={() => cancel()}>Cancel</Text>}
            </div>
        </div>
    </div>
};

export default SubscriptionCard;
