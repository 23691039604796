import {axios} from "../shared/axios";

export const answer = async (trackId, optionSelected) => {
    let result = await axios.put('quizzes/answer', {trackId, optionSelected});
    try {
        return {success: true, data: result.data};
    } catch (e) {
        return {
            success: false, message: result.data.error
        };
    }
};
