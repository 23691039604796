import style from "./input.scss";


const Input = ({placeholder, suffix, onChange, value, type}) => (
    <div className={`${style.inputContainer}`}>
        <input type={type || 'text'} className={`${style.input}`} placeholder={placeholder}
               onChange={(e) => onChange(e.target.value)} value={value}/>
        {suffix && (<div className={`${style.suffix}`}>{suffix}</div>)}
    </div>
);

export default Input;
