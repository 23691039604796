import {axios} from "../shared/axios";
import {route} from "preact-router";

export const login = async ({email, password}) => {
    try {
        let result = await axios.post('session-customers', {email, password});
        if (typeof window !== "undefined") {
            localStorage.setItem('user', JSON.stringify(result.data));
        }
        route('/courses');
        return {success: true};
    } catch (e) {
        return {
            success: false, message: e.response.data.error
        };
    }
};
