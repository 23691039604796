import Header from "../../components/generic/ui-components/header/header";
import style from "./auth.scss";
import {useEffect, useState} from "preact-compat";
import {route} from "preact-router";
import QuizTrackCard from "../../components/quiz-track-card/quiz-track-card";
import {getMyQuizzes} from "../../api/quiz/get-my-quizzes";
import Text from "../../components/generic/ui-components/text/text";

const MyQuizzes = () => {

    const [quizzes, setQuizzes] = useState([]);
    const [user, setUser] = useState(null);

    useEffect(async () => {
        if (typeof window !== "undefined") {
            let user = localStorage.getItem('user');
            if (!user) {
                route('/login');
                return;
            }
            setUser(JSON.parse(localStorage.getItem('user')));
        }
        const result = await getMyQuizzes();
        setQuizzes(result.data);
    }, []);

    return (<>
        <Header></Header>
        <div className={style.space}>
            <div className={style.listContainer}>
                <h2
                    style={{
                        margin: "auto",
                        color: "white",
                    }}
                >My Quizzes</h2>
                {user && <Text type={'text'}>
                    Welcome {user.email}
                </Text>}
                {quizzes.map((o, i) => {
                    return (<QuizTrackCard key={i} track={o}/>)
                })}
            </div>
        </div>
    </>)
};

export default MyQuizzes;
