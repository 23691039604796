import Header from "../../components/generic/ui-components/header/header";
import style from "./auth.scss";
import {useEffect, useState} from "preact-compat";
import {route} from "preact-router";
import Text from "../../components/generic/ui-components/text/text";
import {getQuizResult} from "../../api/quiz/get-quiz-result";
import LayoutContainer from "../../components/generic/ui-components/layout-container/layout-container";
import FooterLayoutContainer
    from "../../components/generic/ui-components/footer-layout-container/footer-layout-container";
import PrimaryButton from "../../components/generic/ui-components/primary-button/primary-button";

const QuizResult = ({matches}) => {
    const {id} = matches;
    const [track, setTrack] = useState(null);
    const [user, setUser] = useState(null);
    useEffect(async () => {
        if (typeof window !== "undefined") {
            let user = localStorage.getItem('user');
            if (!user) {
                route('/login');
                return;
            }
            setUser(JSON.parse(localStorage.getItem('user')));
        }
        const result = await getQuizResult(id);
        if (result.success) {
            setTrack(result.data);
        }

    }, []);

    const getFormattedDate = () => {
        const date = new Date();

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-2);

        return `${month}/${day}/${year}`;
    }

    return (<>
        {track && <>
            <Header title={track.quiz.name}/>
            <LayoutContainer haveHeader={true} haveFooter={true}>
                <div>
                    <Text type={'h3'}>Quiz Result</Text>
                </div>
                <div className={style.mB20}>
                    {user && <Text type={'text'} black={true}>
                        Congratulations {user.email}!
                    </Text>}

                </div>
                <div className={style.listContainer}>
                    <div className={style.scoreCard}>
                        <Text type={'h3'} white={true}>Your score</Text>
                        <Text type={'h3'} white={true}>{track.score} / {track.quiz.totalQuestions}</Text>
                        <Text type={'description'} white={true}>Congratulations! You have passed this test
                            with {(track.score * (100 / track.quiz.totalQuestions)).toFixed(1)}%
                            at {getFormattedDate()}</Text>
                    </div>
                    <div className={style.horizontal}>
                        <div className={`${style.correctCard} ${style.smallCard}`}>
                            <Text type={'h3'}>{track.score}</Text>
                            <Text type={'description'}>Correct Answers</Text>
                        </div>
                        <div className={`${style.wrongCard} ${style.smallCard}`}>
                            <Text type={'h3'}>{track.quiz.totalQuestions - track.score}</Text>
                            <Text type={'description'}>Wrong Answers</Text>
                        </div>
                    </div>
                    <div className={style.horizontal}>
                        <div className={`${style.totalTimeCard} ${style.smallCard}`}>
                            <Text type={'h4'}>{track.totalTime}</Text>
                            <Text type={'description'}>Total Time</Text>
                        </div>
                        <div className={`${style.avgTimeCard} ${style.smallCard}`}>
                            <Text type={'h4'}>{track.avgTimePerAnswer}</Text>
                            <Text type={'description'}>Avg. Time / Answer</Text>
                        </div>
                    </div>
                    <div className={style.cardCenter}>
                        <PrimaryButton onClick={() => route('/quiz?id=' + track.quiz._id)}>Retake</PrimaryButton>
                    </div>
                </div>
                <FooterLayoutContainer>
                    <PrimaryButton onClick={() => route('/courses')}>Go To Courses</PrimaryButton>
                </FooterLayoutContainer>
            </LayoutContainer>
        </>}
    </>)
};

export default QuizResult;
