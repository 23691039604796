import Text from "../../components/generic/ui-components/text/text";
import PrimaryButton from "../../components/generic/ui-components/primary-button/primary-button";
import style from "./auth.scss";
import LayoutContainer from "../../components/generic/ui-components/layout-container/layout-container";
import FooterLayoutContainer
    from "../../components/generic/ui-components/footer-layout-container/footer-layout-container";
import Header from "../../components/generic/ui-components/header/header";
import CreditCardInput from 'react-credit-card-input';
import {route} from "preact-router";
import {useEffect, useState} from "preact-compat";
import {PaymentProcessor} from "../../api/payment/payment";
import Toast from "../../components/generic/ui-components/toast/toast";

const AddPaymentMethod = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [cardNumber, setCardNumber] = useState('');
    const [expiry, setExpiry] = useState('');
    const [cvc, setCvc] = useState('');
    const [showError, setShowError] = useState(false);
    const [text, setText] = useState('');

    useEffect(() => {
        if (typeof window !== "undefined") {

            let script = document.createElement('script');
            if (window.location.href.includes('localhost') || window.location.href.includes('demo')) {
                script.src = "https://demo.convergepay.com/hosted-payments/Checkout.js";
            } else {
                script.src = "https://api.convergepay.com/hosted-payments/Checkout.js";
            }
            document.body.appendChild(script);
        }
    }, []);

    useEffect(async () => {
        if (typeof window !== "undefined") {
            let user = localStorage.getItem('user');
            if (!user) {
                route('/login');
                return;
            }
        }
    }, []);

    const submit = async (e) => {
        setIsLoading(true);
        let payment = new PaymentProcessor({
            amount: 0.1,
            cardNumber: cardNumber,
            expirationDate: expiry,
            cvv: cvc,
            email: JSON.parse(localStorage.getItem('user')).email
        });
        try {
            await payment.pay(e);
            setIsLoading(false);
            route('/my-account');
        } catch (e) {
            setText(e.message);
            setShowError(true);
            setIsLoading(false);
        }
    }

    return (<>
        <Header haveBack={true}></Header>
        <LayoutContainer innerClass={style.contentContainer} haveHeader={true}>
            <div className={style.textCenter}>
                <Text type={'h1'}>Add Payment Method</Text>
            </div>
            <div className={style.cardContainer}>
                <CreditCardInput
                    cardNumberInputProps={{value: cardNumber, onChange: (e) => setCardNumber(e.target.value)}}
                    cardExpiryInputProps={{value: expiry, onChange: (e) => setExpiry(e.target.value)}}
                    cardCVCInputProps={{value: cvc, onChange: (e) => setCvc(e.target.value)}}
                    fieldClassName="input"
                />
            </div>
            <FooterLayoutContainer>
                <PrimaryButton onClick={submit} isLoading={isLoading}>Add</PrimaryButton>
            </FooterLayoutContainer>
        </LayoutContainer>
        <Toast text={text} open={showError} setOpen={setShowError}/>
    </>)
};

export default AddPaymentMethod;
