import {axios} from "../shared/axios";

export const resetPassword = async ({password, token}) => {
    let result = await axios.put('customer-users/change-password', {password, token});
    try {
        return {success: true};
    } catch (e) {
        return {
            success: false, message: result.data.error
        };
    }
};
