import Header from "../../components/generic/ui-components/header/header";
import Text from "../../components/generic/ui-components/text/text";
import PrimaryButton from "../../components/generic/ui-components/primary-button/primary-button";
import style from "./landing.scss";
import LayoutContainer from "../../components/generic/ui-components/layout-container/layout-container";
import {route} from "preact-router";
import {useEffect} from "preact-compat";

const Landing = () => {

    useEffect(async () => {
        if(typeof window !== "undefined") {
            let user = localStorage.getItem('user');
            if (user) {
                route('/courses');
                return;
            }
        }
    }, []);


    return (<>
        <Header></Header>
        <LayoutContainer haveHeader={true} innerClass={style.landingContent}>
            <div className={style.bgImage}></div>
            <div className={style.textCenter}>
                <Text type={'h1'} white={true}>Ace your exam<br/>with our quizzes</Text>
            </div>
            <div>
                <PrimaryButton onClick={() => route('/signup')}>START NOW</PrimaryButton>
            </div>
        </LayoutContainer>
    </>)
};

export default Landing;
