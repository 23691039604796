import style from "./course-card.scss";
import Text from "../generic/ui-components/text/text";
import {route} from "preact-router";

const CourseCard = ({course, advertisement}) => (
    <div className={`${style.courseContainer}`} onClick={() => {
        if (advertisement) {
            route(`/buy-course`)
        } else {
            route(`/quizzes?id=${course._id}`)
        }
    }}>
        <div className={style.courseContent}>
            <Text type={'h4'}>{course.name}</Text>
            <Text
                type={'small-description'}>{course.totalQuizzes} {course.totalQuizzes !== 1 ? "quizzes" : "quiz"}</Text>
            {advertisement && <i><Text
                type={'small-description'}>{`$${course.price}/month`}
            </Text></i>}
        </div>
    </div>
);

export default CourseCard;
