import {axios} from "../shared/axios";

export const removePaymentMethod = async (id) => {
    try {
        await axios.delete('payment-methods/' + id);
        return {success: true};
    } catch (e) {
        return {
            success: false
        };
    }
};
