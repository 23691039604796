import style from "./payment-method-card.scss";
import Text from "../generic/ui-components/text/text";
import {makePaymentMethodDefault} from "../../api/payment/make-payment-method-default";
import {removePaymentMethod} from "../../api/payment/remove-payment-method";

const PaymentMethodCard = ({paymentMethod, refresh, enableLoading}) => {

    const makeDefault = async () => {
        enableLoading();
        await makePaymentMethodDefault(paymentMethod._id);
        refresh();
    };

    const remove = async () => {
        enableLoading();
        await removePaymentMethod(paymentMethod._id);
        refresh();
    };

    return (<div className={style.paymentMethodContainer}>
        <div className={style.paymentMethodContent}>
            <div className={style.imageContainer}>
                <img className={style.cardTypeImage} src={'/assets/' + paymentMethod.cardType + '.svg'}/>
            </div>
            <div className={style.paymentMethodDetails}>
                <Text type={'h4'}>{paymentMethod.cardType}<span className={style.dot}/><span
                    className={style.dot}/><span className={style.dot}/><span
                    className={style.dot}/>{paymentMethod.cardNumber.slice(-4)}</Text>
                <Text type={'small-description'}>
                    Expires {paymentMethod.expirationMonth}/{paymentMethod.expirationYear}
                </Text>
            </div>
        </div>
        <div className={style.paymentMethodActionContainer}>
            <div>
                {paymentMethod.isDefault && <Text type={'text'} black={true}>Default</Text>}
                {!paymentMethod.isDefault && <Text type={'text'} black={true} link={true}
                                                   onClick={() => makeDefault()}>Make
                    Default</Text>}
            </div>
            <div>
                <Text type={'text'} black={true} link={true} onClick={() => remove()}>Remove</Text>
            </div>
        </div>
    </div>);
}

export default PaymentMethodCard;
