import Text from "../../components/generic/ui-components/text/text";
import style from "./auth.scss";
import LayoutContainer from "../../components/generic/ui-components/layout-container/layout-container";
import Header from "../../components/generic/ui-components/header/header";
import {route} from "preact-router";
import {useEffect, useState} from "preact-compat";
import Toast from "../../components/generic/ui-components/toast/toast";
import {getSubscriptions} from "../../api/payment/get-subscriptions";
import {getPaymentMethods} from "../../api/payment/get-payment-methods";
import PaymentMethodCard from "../../components/payment-method-card/payment-method-card";
import SubscriptionCard from "../../components/subscription-card/subscription-card";
import Spinner from "../../components/generic/ui-components/spinner/spinner";

const MyAccount = () => {

    const [showError, setShowError] = useState(false);
    const [text, setText] = useState('Something went wrong.');
    const [isPaymentMethodLoading, setIsPaymentMethodLoading] = useState(false);
    const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);

    useEffect(async () => {
        if (typeof window !== "undefined") {
            let user = localStorage.getItem('user');
            if (!user) {
                route('/login');
                return;
            }
            refreshPaymentMethod();
            refreshSubscriptions();
        }
    }, []);

    const refreshPaymentMethod = async () => {
        setIsPaymentMethodLoading(true);
        const paymentMethodsResult = await getPaymentMethods();
        if (!paymentMethodsResult.success) {
            setShowError(true);
            return;
        }
        setPaymentMethods(paymentMethodsResult.paymentMethods.sort((a, b) => a.isDefault ? -1 : 1));
        setIsPaymentMethodLoading(false);

    }

    const refreshSubscriptions = async () => {
        setIsSubscriptionLoading(true);
        const subscriptionsResult = await getSubscriptions();
        if (!subscriptionsResult.success) {
            setShowError(true);
            return;
        }
        setSubscriptions(subscriptionsResult.subscriptions);
        setIsSubscriptionLoading(false);

    }

    return (<>
        <Header></Header>
        <LayoutContainer innerClass={style.contentContainer} haveHeader={true}>
            <div className={style.textCenter}>
                <Text type={'h1'}>My Account</Text>
            </div>
            <Text type={'h4'}>Payment Methods</Text>
            {isPaymentMethodLoading && <Spinner></Spinner>}
            {!isPaymentMethodLoading && <div className={style.listContainer}>
                {paymentMethods.map((o, i) => <PaymentMethodCard key={i}
                                                                 paymentMethod={o}
                                                                 refresh={() => refreshPaymentMethod()}
                                                                 enableLoading={() => setIsPaymentMethodLoading(true)}
                ></PaymentMethodCard>)}
                <div className={style.cardCenter}>
                    <Text type={'text'} link={true} bold={true} black={true}
                          onClick={() => route('/add-payment-method')}>Add Payment Method</Text>
                </div>
            </div>}
            <Text type={'h4'}>Subscriptions</Text>
            {isSubscriptionLoading && <Spinner></Spinner>}
            {!isSubscriptionLoading && <div className={style.listContainer}>
                {subscriptions.map((o, i) => <SubscriptionCard key={i} subscription={o}
                                                               refresh={() => refreshSubscriptions()}
                                                               enableLoading={() => setIsSubscriptionLoading(true)}></SubscriptionCard>)}
            </div>}
        </LayoutContainer>
        <Toast text={text} open={showError} setOpen={setShowError}/>
    </>)
};

export default MyAccount;
