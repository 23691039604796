import {axios} from "../shared/axios";

export const getPaymentMethods = async () => {
    let result = await axios.get('payment-methods');
    try {
        return {success: true, paymentMethods: result.data};
    } catch (e) {
        return {
            success: false, message: result.data.error
        };
    }
};
