import style from "./header.scss";
import {useState} from "preact-compat";
import SecondaryButtonInes from "../../ui-components/secondary-button/secondary-button.ines";
import {route} from "preact-router";

const Header = ({title, haveBack = false, back = null}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [hasUser, setHasUser] = useState(typeof window !== "undefined" ? localStorage.getItem('user') : null);
    return (<>
        <div>
            <ul className={`${style.menu} ${isOpen && style.open}`}>
                <li className={`${style.closeIcon}`} onClick={() => {
                    setIsOpen(false);
                }}>
                    <img src='/assets/global/close.svg'/>
                </li>
                {!hasUser && (<>
                    <li className={style.btn}>
                        <SecondaryButtonInes innerClass={style.black}
                                             onClick={() => route('/login')}>Login</SecondaryButtonInes>
                    </li>
                    <li className={style.btn}>
                        <SecondaryButtonInes innerClass={style.black}
                                             onClick={() => route('/signup')}>Signup</SecondaryButtonInes>
                    </li>
                </>)}
                {hasUser && (<>
                    <li className={style.btn}>
                        <SecondaryButtonInes innerClass={style.black}
                                             onClick={() => {
                                                 route('/my-quizzes');
                                             }}>My Quizzes</SecondaryButtonInes>
                    </li>
                </>)}
                {hasUser && (<>
                    <li className={style.btn}>
                        <SecondaryButtonInes innerClass={style.black}
                                             onClick={() => {
                                                 route('/my-account');
                                             }}>My Account</SecondaryButtonInes>
                    </li>
                </>)}
                {hasUser && (<>
                    <li className={style.btn}>
                        <SecondaryButtonInes innerClass={style.black}
                                             onClick={() => {
                                                 route('/buy-course');
                                             }}>Buy Course</SecondaryButtonInes>
                    </li>
                </>)}
                {hasUser && (<>
                    <li className={style.btn}>
                        <SecondaryButtonInes innerClass={style.black}
                                             onClick={() => {
                                                 route('/courses');
                                             }}>Courses</SecondaryButtonInes>
                    </li>
                </>)}
                {hasUser && (<>
                    <li className={style.btn}>
                        <SecondaryButtonInes innerClass={style.black}
                                             onClick={() => {
                                                 if (typeof window !== "undefined") {
                                                     localStorage.removeItem('user');
                                                 }
                                                 route('/login');
                                             }}>Logout</SecondaryButtonInes>
                    </li>
                </>)}

            </ul>
            <div className={`${style.header}`}>
                {!haveBack && <div className={`${style.burgerIcon}`} onClick={() => {
                    setIsOpen(true)
                }}>
                    <img src='/assets/global/menu.svg'/>
                </div>}
                {haveBack && <div className={`${style.burgerIcon}`} onClick={() => {
                    if (!back) {
                        history.back();
                    } else {
                        back();
                    }
                }}>
                    <img src='/assets/global/back-header.svg'/>
                </div>}
                <div className={`${style.title}`}>
                    {title ? title : <img src='/assets/logo.png'/>}
                </div>
            </div>
        </div>
    < />);
}

export default Header;
